import { Box, Container, Heading, Text, useColorMode } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import ImgTextModule from '../../components/elements/ImgTextModule';
import FadeInAnimation from '../../components/UX/FadeInAnimation';
import MagicText from '../../components/UX/MagicText';
import TeamDuo from '../../components/TeamDuo';
import TeamTri from '../../components/TeamTri';
import KontaktFooter from '../../components/KontaktFooter';
const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Nils ykstra</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      {/* CONTAINER MAGICTEXT */}

      <Container minW='100%' mt={0.5} p={0}>
        <MagicText
          text={
            'Das Ziel ist Vertrauen. Unser eigener Anspruch von Offenheit, Wertschätzung und Zuverlässigkeit findet sich in allem wieder, was wir tun.'
          }
          quoteOrigin='Nils Dykstra'
          fontSize={{ md: '6xl', base: '3xl' }}
          coloranimtext={'#ffaa00'}
          scrollLength={'250vh'}
          gap={{ md: 0, base: 0 }} //gap between words
          videoSrc='nils.mp4'
          scrollStartFactor={-0.1}
          scrollEndFactor={0.5}
          textColor={'brand.yellow'}
          showProgressCircle={true}
          progressColor={'brand.yellow'}
          showProgressLine={true}
          progressLineColor={'brand.violet'}
        />
      </Container>

      <Box bg='brand.gray.1000'>
        <Container variant='layoutContainer' py={32} px={8}>
          <FadeInAnimation
            threshold={0.1}
            delay={0}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Heading color='brand.violet' fontSize={{ base: '4xl', lg: '6xl' }}>
              Nils Dykstra
            </Heading>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text my={4} fontSize={'4xl'} color='brand.green'>
              Ein „Moin Moin“ ist schon Zuviel, sagt man im Norden!
            </Text>
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'xl'}
              color='brand.gray.100'
            >
              „Geordnet und konstruktiv“ ist das Geheimnis, dass wurde mir in
              den letzten Jahren in der Windenergiebranche national und
              international so beigebracht.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              Nach jahrelanger HR-Arbeit in Konzernstrukturen und überregionalem
              Wirken im Management, darf ich nun viele erfolgreiche Prozesse,
              Erfahrungen und Netzwerke in meine neue Aufgabe an der Küste
              einfließen lassen.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={1}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              Als Nordlicht, Baujahr 77 und in Wilhelmshaven geboren, wird
              Offenheit, Loyalität und Wertschätzung bei mir großgeschrieben.
              Ein Unternehmen maßgeblich mit zu entwickeln und zu gestalten,
              zumal noch mit zwei lang vertrauten Gesellschaftern ganz
              unterschiedlichen Charakters aber mit der gleichen
              Werteorientierung, das begeistert und fordert mich im besonderen
              Maße.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              Bis dahin,
              <br />
              <strong>Ihr Nils Dykstra</strong>
            </Text>
          </FadeInAnimation>
        </Container>
      </Box>
      <TeamTri />
      <KontaktFooter />
    </>
  );
};

export default IndexPage;
